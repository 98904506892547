import React from 'react'
import * as styles from './decoration.module.scss'

const TextDecoration = ({decoration, setDecoration}) => {
    const options = ['', 'underline', 'line-through']
    return(
        <div className={styles.container}>
            {options.map((item) => {
                return <span className={decoration === item ? styles.active : undefined} onClick={() => setDecoration(item)} key={item}>Aa</span>
            })}
        </div>
    )
}

export default TextDecoration;