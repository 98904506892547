import React from 'react'
import Dropdown from '../../../../Common/Dropdown/Dropdown'
import HamburgerIcon from '../../../../../assets/svg/3d-maker/header/hamburger.svg'
import UndoIcon from '../../../../../assets/svg/undo.svg'
import RedoIcon from '../../../../../assets/svg/redo.svg'
import * as styles from './file.module.scss'
import { useDispatch } from 'react-redux'
import { resetCanvas, setDimensions } from '../../../../../slices/canvas-slice'
import { navigate } from 'gatsby-link'
import { saveAs } from "file-saver";
import { store } from '../../../../../../configureStore'
import Firebase from '../../../../../firebase/index'
import API from '../../../../../firebase/api'
import { toast } from 'react-toastify'
import { getDataAndThumbnail, userLoggedIn } from '../../../../Common/Functions'
import { setLoader } from '../../../../../slices/user-slice'

const Icon = () => {
    return(
        <div className={styles.container}>
            <HamburgerIcon />
        </div>
    )
}

export default () => {
    const dispatch = useDispatch()

    async function save(){
        if(!userLoggedIn()){
            return
        }
        dispatch(setLoader(true))
        try {
            let {data, thumbnail} = getDataAndThumbnail('2d')
            await Firebase.saveTemplateToStorage(data, thumbnail)
            await API.saveNewTemplateUser(data.uuid, {
                name: data.name,
                type: '2d', 
                thumbnail: `${data.uuid}.png`, 
                animated: false, 
                original_template_id: data.originalTemplateId,
            })
            toast.success('✔ Saved')
        }
        catch(error) {
            toast.error('Something went wrong...')
        }
        dispatch(setLoader(false))
    }

    React.useEffect(() => {
        function editState(e){
            if(e.keyCode == 90 && (e.ctrlKey || e.metaKey)){
                dispatch({type: 'UNDO'})
            }
            else if(e.keyCode == 89 && (e.ctrlKey || e.metaKey)){
                dispatch({type: 'REDO'})
            }
        }
        window.addEventListener('keydown', editState)
        return () => {
          window.removeEventListener('keydown', editState)
        }
    }, [])

    const options = [
        {
            name: 'My Templates',
            onClick: () => navigate('/templates')
        },
        {
            name: 'New',
            onClick: () => dispatch(resetCanvas())
        },
        {
            name: 'Edit',
            options: [
                {
                    name: "Undo",
                    onClick: () => dispatch({type: 'UNDO'}),
                    icon: <UndoIcon />,
                },
                {
                    name: "Redo",
                    onClick: () => dispatch({type: 'REDO'}),
                    icon: <RedoIcon />
                },
            ]
        },
        {
            name: 'Save',
            onClick: save
        },
        {
            name: 'Dimensions',
            onClick: () => dispatch(setDimensions({modal: true}))
        }
    ]
    return(
        <Dropdown parent={<Icon />} options={options} />
    )
}