import React from "react";
import { useSelector } from "react-redux";
import AdvancedTab from "../AdvancedTab/AdvancedTab";
import DeviceTab from "../DeviceTab/DeviceTab";
import ImageTab from "../ImageTab/ImageTab";
import TextTab from "../TextTab/TextTab";
import * as styles from "./displayedtab.module.scss";


export default () => {
  let id = useSelector((state) => state.CanvasSlice.present.selectedId);
  let integerId = id.replace(/\D/g, "");
  return (
    <div className={styles.mainContainer} style={{width: id ? 260 : 0}}>
      {id === "advanced" ? (
        <AdvancedTab />
      ) : id.includes("device") ? (
        <DeviceTab id={integerId} />
      ) : id.includes("image") ? (
        <ImageTab id={integerId} />
      ) : id.includes("text") ? (
        <TextTab id={integerId} />
      ) : null}
    </div>
  );
};
