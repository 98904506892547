import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeClass, setClassParam } from "../../../../../slices/canvas-slice";
import DeleteButton from "../../../../Common/DeleteButton/DeleteButton";
import UploadMedia from "../../../../Common/UploadMedia/UploadMedia";
import FrameSelector from "./FrameSelector/FrameSelector";
import Shadow from "../../Shadow/Shadow";
import Slider from "../../../../Common/Slider/Slider";
import * as styles from "./devicetab.module.scss";

export default ({id}) => {
  let currentDevice = useSelector((state) => state.CanvasSlice.present.devices[id]);
  let dispatch = useDispatch();

  function changeParam(param, value) {
    dispatch(setClassParam({class: 'devices', id, param, value}));
  }

  function removeDevice() {
    dispatch(removeClass({class: "devices", id}));
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Device</span>
        <div className={styles.settings}>
        <div className={styles.row}>
            <span>Screenshot</span>
            <UploadMedia
            label={"Choose file"}
            onUpload={(url) => changeParam('screenshot_url', url)}
            cropRatio={
              currentDevice.frame.proportion
                ? currentDevice.frame.frame_size.width /
                  (currentDevice.frame.frame_size.height *
                    currentDevice.frame.proportion)
                : null
            }
              style={{
                padding: 0,
                fontSize: 12,
                height: '100%'
              }}
            />
          </div>
          <FrameSelector currentFrame={currentDevice.frame} changeParam={changeParam}/>
          <div className={styles.row}>
            <span>Size</span>
            <Slider
              min={0}
              max={200}
              step={0.1}
              value={currentDevice.width / 10}
              onChange={(value) => changeParam("width", value * 10)}
            />
          </div>
          </div>
          </div>
          <div className={styles.section}>
      <span className={styles.title}>Shadow</span>
        <div className={styles.settings}>
        <Shadow currentNode={currentDevice} changeNode={changeParam} />
        </div>
      </div>
      <DeleteButton onClick={removeDevice} style={{marginTop: 10}} />
    </div>
  );
};
