import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDimensions, setNumberOfScreenshots } from '../../../../../slices/canvas-slice'
import DraggableSetting from '../../../../Common/DraggableSetting/DraggableSetting'
import * as styles from './dimensions.module.scss'

const DimensionsModal = () => {
    const dispatch = useDispatch()
    const dimensions = useSelector(state => state.CanvasSlice.present.meta.dimensions)
    const numOfScreenshots = useSelector(state => state.CanvasSlice.present.meta.numOfScreenshots)

    return(
        <DraggableSetting handle={'Settings'} position={{x: 10, y: 10}} show={dimensions.modal} onClose={() => dispatch(setDimensions({modal: false}))}>
            <div>
                <div className={styles.row}>
                    <span>Dimensions</span>
                    <input type={'number'} style={{marginRight: 10}} defaultValue={dimensions.width} onChange={e => dispatch(setDimensions({width: parseInt(e.target.value)}))} />
                    <input type={'number'} defaultValue={dimensions.height} onChange={e => dispatch(setDimensions({height: parseInt(e.target.value)}))} />
                </div>
                <div className={styles.row}>
                    <span>Partitions</span>
                    <input type={'number'} style={{width: 30}} defaultValue={numOfScreenshots} onChange={e => dispatch(setNumberOfScreenshots(parseInt(e.target.value)))}/>
                </div>
            </div>
        </DraggableSetting>
    )
}

export default DimensionsModal;