import React from 'react'
import Slider from '../../../Common/Slider/Slider'
import ColorPicker from "../../../Common/ColorPicker/ColorPicker";
import * as styles from './shadow.module.scss'
import GroupedSetting from '../../../Common/GroupedSetting/GroupedSetting';
import DraggableSetting from "../../../Common/DraggableSetting/DraggableSetting";
import TextDecoration from "../../../Common/Text/Decoration/Decoration";
import CustomColorPicker from "../../../Common/CustomColorPicker/CustomColorPicker";
import {
  EditableInput,
} from "react-color/lib/components/common";
import tinycolor from 'tinycolor2';
// const Shadow = ({currentNode, changeNode}) => {
//     return(
//         <GroupedSetting title={'Shadow'}> 
//             <ColorPicker startColor={currentNode.shadowColor || "#22194D"} onChange={(color) => changeNode('shadowColor', color)} />
//             <Slider min={0} max={1} step={0.01} name={'Opacity'} value={currentNode.shadowOpacity === undefined ? 1 : currentNode.shadowOpacity} onChange={(value) => changeNode('shadowOpacity', parseFloat(value))}/>
//             <Slider min={0} max={10} step={0.05} name={'Blur'} value={currentNode.shadowBlur || 0} onChange={(value) => changeNode('shadowBlur', parseFloat(value))}/>
//             <Slider min={-10} max={10} step={0.001} name={'Shadow Offset X'} value={currentNode.shadowOffsetX || 0} onChange={(value) => changeNode('shadowOffsetX', parseFloat(value))} />
//             <Slider min={-10} max={10} step={0.001} name={'Shadow Offset Y'} value={currentNode.shadowOffsetY || 0} onChange={(value) => changeNode('shadowOffsetY', parseFloat(value))} />
//         </GroupedSetting>
//     )
// }

const Shadow = ({currentNode, changeNode}) => {
    let currentColor = currentNode.shadowColor || "#22194D"
    return(
        <>
            <div className={styles.row}>
              <span>Color</span>
              <div className={styles.colorContainer}>
              <DraggableSetting parent={
                  <div className={styles.color}>
                    <div style={{backgroundColor: currentColor}} />
                  </div>
              } handle={'Color'}>
                <CustomColorPicker onColorChange={color => changeNode('shadowColor', color)} color={currentColor} />
              </DraggableSetting>
              <div className={styles.input}>
                      <EditableInput
                      value={ '#' + tinycolor(currentColor).toHex() }
                      onChange={ (color) => changeNode('shadowColor', tinycolor(color).toRgbString()) } />
              </div>
              </div>
            </div>
            <div className={styles.row}>
            <span>Opacity</span>
            <Slider min={0} max={1} step={0.01} value={currentNode.shadowOpacity === undefined ? 1 : currentNode.shadowOpacity} onChange={(value) => changeNode('shadowOpacity', parseFloat(value))}/>
          </div>
          <div className={styles.row}>
            <span>Blur</span>
            <Slider min={0} max={10} step={0.05} value={currentNode.shadowBlur || 0} onChange={(value) => changeNode('shadowBlur', parseFloat(value))}/>
          </div>
          <div className={styles.row}>
            <span>Offset X</span>
            <Slider min={-10} max={10} step={0.001} value={currentNode.shadowOffsetX || 0} onChange={(value) => changeNode('shadowOffsetX', parseFloat(value))} />
          </div>
          <div className={styles.row}>
            <span>Offset Y</span>
            <Slider min={-10} max={10} step={0.001} value={currentNode.shadowOffsetY || 0} onChange={(value) => changeNode('shadowOffsetY', parseFloat(value))} />
          </div>
        </>
    )
}
export default Shadow;