import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../../Common/Slider/Slider";
import DropdownIcon from '../../../../../assets/svg/dropdown.svg'
import { removeClass, setClassParam } from "../../../../../slices/canvas-slice";
import ColorPicker from "../../../../Common/ColorPicker/ColorPicker";
import Alignment from "../../../../Common/Alignment/Alignment";
import DeleteButton from "../../../../Common/DeleteButton/DeleteButton";
import FontPicker from "../../../../Common/FontPicker/FontPicker";
import NewFontPicker from "../../../../Common/FontPicker/New";
import GroupedSetting from "../../../../Common/GroupedSetting/GroupedSetting";
import Select from "../../../../Common/Select/Select";
import TrueFalse from "../../../../Common/TrueFalse/TrueFalse";
import Shadow from "../../Shadow/Shadow";
import * as styles from "./texttab.module.scss";
import DraggableSetting from "../../../../Common/DraggableSetting/DraggableSetting";
import TextDecoration from "../../../../Common/Text/Decoration/Decoration";
import CustomColorPicker from "../../../../Common/CustomColorPicker/CustomColorPicker";
import {
  EditableInput,
} from "react-color/lib/components/common";
import tinycolor from 'tinycolor2';

export default ({id}) => {
  let dispatch = useDispatch()
  let [fontWeights, setFontWeights] = React.useState([])
  let currentText = useSelector(
    (state) => state.CanvasSlice.present.texts[id]
  );
  let family = currentText.font?.family || currentText.fontFamily
  let style = currentText.font?.style || currentText.fontStyle
  // let currentFontWeight = currentText.fontStyle.replace(/\D/g,'')

//   React.useEffect(() => {
//       async function getFontOnPageLoad(){
//           if(id){
//               let newWeights = await fetchNewWeights(currentText.fontFamily)
//               setFontWeights(newWeights)
//           }
//       }
//       getFontOnPageLoad()
//   }, [currentText])

//   async function fetchNewWeights(family){
//       let response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU')
//       let responseJson = await response.json() 
//       for(let i=0; i<responseJson.items.length; i++){
//           if(family === responseJson.items[i].family){
//               let newWeights = getWeights(responseJson.items[i].variants)
//               return newWeights
//           } 
//       }
//       return []
//   }

//   function getWeights(variants){
//       let newWeights = variants.reduce((list, variant) => {
//           if(/^\d+$/.test(variant)){
//               list.push(variant)
//           }
//           return list
//       }, [])
//       if(newWeights.length === 0) {
//           newWeights = ['400']
//       }
//       return newWeights
//   }

  useEffect(() => {
    (async () => {
        // Get weights
        if(currentText.font?.custom){
          setFontWeights(['Custom'])
        }
        else{
          let response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU')
          let responseJson = await response.json() 
          for(let i=0; i<responseJson.items.length; i++){
              if(family === responseJson.items[i].family){
                setFontWeights(responseJson.items[i].variants)
                break
              } 
          }
        }
    })()
  }, [])
  
  async function changeText(param, value){
      dispatch(setClassParam({class: 'texts', id, param, value}))
  }

  function changeFont(family, styles, custom){
    setFontWeights(styles)
    dispatch(setClassParam({class: 'texts', id, param: 'font', value: {
        family,
        style: styles[0],
        custom
    }}))
  }

  function removeText(){
      dispatch(removeClass({class: 'texts', id}))
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Text</span>
        <div className={styles.settings}>
          <div className={styles.row}>
            <span>Content</span>
            <textarea value={currentText.text} onChange={e => changeText('text', e.target.value)} />
          </div>
          <div className={styles.row}>
            <span>Font</span>
            <DraggableSetting position={{x: 190, y: -28}} parent={<div className={styles.selectorButton}><span>{family}</span><DropdownIcon /></div>} handle={'Font'}>
              <NewFontPicker currentFont={family} onChange={changeFont}/>
            </DraggableSetting>
          </div>
          <div className={styles.row}>
            <span>Style</span>
            <Select onChange={value => dispatch(setClassParam({class: 'texts', id, param: 'font', value: {
            family,
            custom: currentText.font?.custom,
            style: value,
            }}))} options={fontWeights} value={style} />
          </div>
          <div className={styles.row}>
            <span>Size</span>
            <Slider value={currentText.scale.x} onChange={(value) => changeText('scale', {x: value, y: value})} />
          </div>
          <div className={styles.row}>
            <span>Letter</span>
            <Slider min={-5} max={100} step={0.01} value={currentText.letterSpacing || 0} onChange={(value) => changeText('letterSpacing', parseFloat(value))}/>
          </div>
          <div className={styles.row}>
            <span>Line</span>
            <Slider min={0} max={10} step={0.001} value={currentText.lineHeight || 1} onChange={(value) => changeText('lineHeight', parseFloat(value)) } />
          </div>
          <div className={styles.row}>
            <span>Align</span>
            <Alignment active={currentText.align} onClick={(option) => changeText('align', option)}/>
          </div>
          <div className={styles.row}>
            <span>Decoration</span>
            <TextDecoration decoration={currentText.textDecoration} setDecoration={(option) => changeText('textDecoration', option)}/>
          </div>
          <div className={styles.row}>
            <span>Color</span>
            <div className={styles.colorContainer}>
            <DraggableSetting
            position={{x: 190, y: -28}}
            parent={
                <div className={styles.color}>
                  <div style={{backgroundColor: currentText.fill}} />
                </div>
            } handle={'Color'}>
              <CustomColorPicker onColorChange={color => changeText('fill', color)} color={currentText.fill} />
            </DraggableSetting>
            <div className={styles.input}>
                    <EditableInput
                    value={ '#' + tinycolor(currentText.fill).toHex() }
                    onChange={ (color) => changeText('fill', tinycolor(color).toRgbString()) } />
            </div>
            </div>
          </div>
          {/* <Shadow currentNode={currentText} changeNode={changeText} />
          <DeleteButton onClick={removeText} style={{marginTop: 10}} /> */}
        </div>

      </div>
      <div className={styles.section}>
      <span className={styles.title}>Shadow</span>
        <div className={styles.settings}>
          <Shadow currentNode={currentText} changeNode={changeText} />
        </div>
      </div>
      <DeleteButton onClick={removeText} style={{marginTop: 10}} />
    </div>
  );
};