import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeClass, setClassParam } from "../../../../../slices/canvas-slice";
import CollectionComponent from "../../../../Common/CollectionComponent/CollectionComponent";
import DraggableSetting from "../../../../Common/DraggableSetting/DraggableSetting";
import DeleteButton from "../../../../Common/DeleteButton/DeleteButton";
import UploadMedia from "../../../../Common/UploadMedia/UploadMedia";
import Shadow from "../../Shadow/Shadow";
import Slider from "../../../../Common/Slider/Slider";
import DropdownIcon from '../../../../../assets/svg/dropdown.svg'
import * as styles from './imagetab.module.scss'

export default ({id}) => {
  let dispatch = useDispatch();
  let currentImage = useSelector((state) => state.CanvasSlice.present.images[id]);

  function removeImage() {
    dispatch(removeClass({class: "images", id}));
  }

  function changeParam(param, value) {
    dispatch(setClassParam({class: 'images', id, param, value}));
  }


  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Image</span>
        <div className={styles.settings}>
        <div className={styles.row}>
            <span>Source</span>
            <UploadMedia
              onUpload={(url) => changeParam("image_url", url)}
              label={"Choose file"}
              style={{
                padding: 0,
                fontSize: 12,
                height: '100%'
              }}
            />
          </div>
          <div className={styles.row}>
            <span>Size</span>
            <Slider
            min={0}
            max={200}
            step={0.01}
            value={currentImage.width / 10}
            onChange={(value) => changeParam("width", value * 10)}
          />
          </div>
          <div className={styles.row}>
            <span>Collections</span>
            <DraggableSetting position={{x: 190, y: -28}} parent={<div className={styles.selectorButton}><span>Store Badges</span><DropdownIcon /></div>} handle={'Store Badges'}>
              <CollectionComponent
              requiredCollections={["Store Badges"]}
              onSelect={(src) => changeParam("image_url", src)}
              canvasProportion={0}
              style={{
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
            </DraggableSetting>
            {/* <Slider
            min={0}
            max={200}
            step={0.01}
            value={currentImage.width / 10}
            onChange={(value) => changeParam("width", value * 10)}
          /> */}
          </div>
          {/* <h3>
            Collections
          </h3>
          <CollectionComponent
            requiredCollections={["Store Badges"]}
            onSelect={(src) => changeParam("image_url", src)}
            canvasProportion={0}
            style={{
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          /> */}

    </div>
    </div>
    <div className={styles.section}>
      <span className={styles.title}>Shadow</span>
        <div className={styles.settings}>
          <Shadow currentNode={currentImage} changeNode={changeParam} />
        </div>
      </div>
      <DeleteButton onClick={removeImage} style={{marginTop: 10}} />
    </div>
  )
};
