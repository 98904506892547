import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from "file-saver";
import { toast } from 'react-toastify';
import { store } from '../../../../../configureStore';
import API from '../../../../firebase/api';
import { setName, setSelectedId } from '../../../../slices/canvas-slice';
import { setLoader } from '../../../../slices/user-slice';
import Download from '../../../Common/Download/Download';
import File from './File/File';
import * as styles from './header.module.scss';
import Menu from './Menu/Menu';
import { dataURItoBlob } from '../../../Common/Functions';

var JSZip = require("jszip");

const ThreeHeader = () => {
  const dispatch = useDispatch()
  let data = useSelector(state => state.CanvasSlice.present)
  let currentName = data.name
  let dimensions = data.meta.dimensions
  let formats = [{name: 'JPEG', value: 'jpeg'}, {name: 'PNG (32-bit)', value: 'png'}]

  let nameRef = useRef()

  useEffect(() => {
    // Update name
    if(nameRef.current && currentName !== nameRef.current.value){
        nameRef.current.value = currentName
    }
  }, [currentName])


      async function rename(name){
        if(name === currentName){
          return
        }
        let uuid = store.getState().CanvasSlice.present.uuid
        let saved = await API.doesTemplateExist(uuid)
        if(saved){
            let response = await API.editTemplate(uuid, {name})
            if(response){
                dispatch(setName(name))
                toast.success('✔ Renamed')
            }else {
                toast.error('Something went wrong...')
            }
        }else {
            dispatch(setName(name))
            toast.success('✔ Renamed')
        }
      }

      function onDownload(scale, free, format){
        dispatch(setLoader(true))
        dispatch(setSelectedId('')) // Remove transformers
      
        setTimeout(() => {
          let zip = new JSZip();
          let canvas = data.stageRef.clone()

          // let ratio = data.stageRef.attrs.scaleX - Number.EPSILON*1000000000
          let canvasHeight = data.stageRef.attrs.height
          let canvasWidth = data.stageRef.attrs.width

          let ratio = dimensions.height / canvasHeight

          let numOfScreenshots = data.meta.numOfScreenshots
          let gapWidth = data.meta.gapWidth
          let singleScreenshotWidth = (canvasWidth - (gapWidth * (numOfScreenshots - 1) / ratio))/numOfScreenshots
      
          let pixelRatio = (ratio * scale) + Number.EPSILON*1000000000


          function imageCallback(img, index){
            let image = dataURItoBlob(img.src);
            zip.file(`image${index}.${format}`, image, { base64: true });
      
            if(index == numOfScreenshots){
              zip.generateAsync({ type: "blob" }).then(async (blob) => {
                API.onUserDownload('2d', free)
                saveAs(blob, "previewed.zip");
                dispatch(setLoader(false))
              });
            }
          }
          for(let i=0; i < numOfScreenshots; i++){
            let x = i * (singleScreenshotWidth + (gapWidth / ratio ))
            canvas.toImage({callback: (img) => imageCallback(img, i + 1), x, y:0, width: singleScreenshotWidth, height: canvasHeight, pixelRatio, mimeType: `image/${format}`, quality: 1})
          }
        }, 100)
      }

    return(
        <div className={styles.container}>
            <div className={styles.left}>
                <File />
                <Menu />
            </div>
            <div className={styles.center}>
                <input ref={nameRef} onBlur={(e) => rename(e.target.value)} defaultValue={currentName} placeholder={'Untitled'}/>
            </div>
            <div className={styles.right}>
              <Download onDownload={onDownload} formats={formats} dimensions={dimensions} />
            </div>
        </div>

    )
}

export default ThreeHeader;