import React from "react";
import { useDispatch } from "react-redux";
import {
  selectCanvasData,
  setActive, setDevices,
  setImages,
  setSelectedId,
  setStageRef, setTexts,
  setBackground
} from "../../../slices/canvas-slice";
import { setActiveCanvas } from "../../../slices/user-slice";
import MakerBar from "../../Common/MakerBar/MakerBar";
import Header from './Header/Header'
import Canvas from "./Canvas/Canvas";
import * as styles from "./maker.module.scss";
import DisplayedTab from "./Tabs/DisplayedTab/DisplayedTab";
import Modals from "./Modals/Modals";

export default () => {
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setActive(true));
    dispatch(setActiveCanvas("2d"));
  }, []);
  return (
    <React.Fragment>
      <div className={styles.phoneDiv}>
        <span>Unavailable on small screens</span>
        <span>Please use a tablet or a desktop to access our maker</span>
      </div>
      <div className={styles.makerMain}>
        <Header />
        <div
          id={"main-div-id"}
          style={{
            display: "flex",
            overflow: "hidden",
            flex: 1,
            backgroundColor: "#f7f7f7",
          }}
        >
          {/* <SideTabs /> */}
          <DisplayedTab />
          <div
            id={"canvasDom"}
            style={{
              display: "flex",
              flex: 1,
              position: "relative",
              overflow: "hidden",
              flexDirection: "column",
              backgroundColor: "#f7f7f7",
              height: "calc(100vh - 64px)",
            }}
          >
            <Modals />
            <div className={styles.outerParent}>
              <div
                id={"stage-parent"}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Canvas
                  selector={selectCanvasData}
                  setDevices={setDevices}
                  setImages={setImages}
                  setSelectedId={setSelectedId}
                  setTexts={setTexts}
                  setStageRef={setStageRef}
                  setBackground={setBackground} // Delete after 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
