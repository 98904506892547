import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDimensions,
  setNumberOfScreenshots
} from "../../../../../slices/canvas-slice";
import * as styles from "./advancedtab.module.scss";


export default () => {
  let data = useSelector((state) => state.CanvasSlice.present);
  let dispatch = useDispatch();

  return (
    <div className={styles.advancedTabContainer}>
      <h3>Dimensions</h3>
      <div className={styles.dimensionsDiv}>
        <div>
          <span>Width:</span>
          <input
            type="number"
            value={data.meta.dimensions.width}
            onChange={(e) =>
              e.target.value <= 5000 &&
              e.target.value >= 0 &&
              Number.isInteger(parseInt(e.target.value))
                ? dispatch(
                    setDimensions({
                      ...data.meta.dimensions,
                      width: parseInt(e.target.value),
                    })
                  )
                : null
            }
          />
        </div>
        <span>X</span>
        <div>
          <span>Height:</span>
          <input
            type="number"
            value={data.meta.dimensions.height}
            onChange={(e) =>
              e.target.value <= 5000 &&
              e.target.value >= 0 &&
              Number.isInteger(parseInt(e.target.value))
                ? dispatch(
                    setDimensions({
                      ...data.meta.dimensions,
                      height: parseInt(e.target.value),
                    })
                  )
                : null
            }
          />
        </div>
      </div>
      <span className={styles.dimensionsTip}>
        * Dimensions of each partition
      </span>
      <h3>Number of Partitions</h3>
      <input
        type="number"
        value={data.meta.numOfScreenshots}
        onChange={(e) =>
          e.target.value > 0 && Number.isInteger(parseInt(e.target.value))
            ? dispatch(setNumberOfScreenshots(Math.round(e.target.value)))
            : null
        }
      />
      {/* <h3>Reset Canvas</h3>
            <button onClick={() => dispatch(resetCanvas())}>Reset Canvas</button> */}
    </div>
  );
};
