import React from 'react';
import api from '../../../../../../firebase/api';
import ColorPicker from '../../../../../Common/ColorPicker/ColorPicker';
import CustomColorPicker from '../../../../../Common/CustomColorPicker/CustomColorPicker';
import DraggableSetting from '../../../../../Common/DraggableSetting/DraggableSetting';
import Select from '../../../../../Common/Select/Select';
import * as styles from './frameselector.module.scss';

const FrameSelector = ({currentFrame, changeParam}) => {
    const [frames, setFrames] = React.useState([])

    React.useEffect(() => {
        async function init(){
            let options = await api.getAllFrames()
            options.sort((a,b) => {
                var textA = a.device.toUpperCase();
                var textB = b.device.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
            setFrames(options)
        }
        init()
    }, [])

    const options = React.useMemo(() => {
        return frames.reduce((list, item) => {
            list.push(item.device)
            return list
        }, ['Dynamic Frame'])
    }, [frames])

    function onChange(name){
        let frame
        if(name === 'Dynamic Frame'){
            frame = {
                device: "Dynamic Frame",
                stroke_factor: 20,
                radius_factor: 20,
                color: "white"
            }
        }
        else{
            frame = frames.find(item => item.device === name)
        }

        changeParam('frame', frame)
    }

    function onColorChange(colorIndex){
        let frame = {...currentFrame, color_index: colorIndex}
        changeParam('frame', frame)
    }

    const PresetColors = () => currentFrame.children.map((child, index) => {
        let color = child.color
        let colorIndex = currentFrame['color_index'] || 0
        let active = colorIndex === index
        return(
            <div key={color} onClick={() => onColorChange(index)} className={active ? styles.active : null} style={{background: color}} />
        )
    })
    return(
        <>
            <div className={styles.row}>
            <span>Frame</span>
            <Select options={options} value={currentFrame.device} onChange={onChange} />
          </div>
          <div className={styles.row}>
            <span>Color</span>
            <DraggableSetting 
            position={{x: 190, y: -28}}
            parent={
                <div className={styles.color}>
                  <div style={{backgroundColor: currentFrame.device === 'Dynamic Frame' ? currentFrame.color : currentFrame.children[currentFrame['color_index'] || 0].color}} />
                </div>
            } 
            handle={'Color'}>
                {currentFrame.device === 'Dynamic Frame' ? 
                <CustomColorPicker onColorChange={color => changeParam('frame', {...currentFrame, color})} color={currentFrame.color} /> :
                <div className={styles.presetColors}>
                    <PresetColors />
                </div>}
            </DraggableSetting>
          </div>

        {/* <h3>Colors</h3>
        <div className={styles.presetColors}>
            {currentFrame.device === 'Dynamic Frame' ? 
            <ColorPicker startColor={currentFrame.color} onChange={color => changeParam('frame', {...currentFrame, color})} /> :
            <PresetColors />}
        </div> */}
        </>
    )
}

export default FrameSelector;