import Tippy from '@tippyjs/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import BackgroundIcon from '../../../../../assets/svg/3d-maker/header/background.svg'
import DevicesIcon from '../../../../../assets/svg/3d-maker/header/cube.svg'
import ImageIcon from '../../../../../assets/svg/3d-maker/header/image.svg'
import TextIcon from '../../../../../assets/svg/3d-maker/header/text.svg'
import { addClass, setBackground } from '../../../../../slices/canvas-slice'
import * as styles from './menu.module.scss'

const Items = ({items}) => {
    return items.map(({icon: Icon, content, onClick}, index) => {
        return  <Tippy content={content} placement={'bottom'} key={index}>
        <div className={styles.icon} onClick={onClick}>
            <Icon />
        </div>
    </Tippy>
    })
}

export default () => {
    const dispatch = useDispatch()

    // Device, Text, Image, Background, Camera, Advanced
    const items = [
        {
            content: 'Add Device',
            icon: DevicesIcon,
            onClick: () => dispatch(addClass('devices'))
        },
        {
            content: 'Add Text',
            icon: TextIcon,
            onClick: () => dispatch(addClass('texts'))
        },
        {
            content: 'Add Image',
            icon: ImageIcon,
            onClick: () => dispatch(addClass('images'))
        },
        {
            content: 'Background',
            icon: BackgroundIcon,
            onClick: () => {
                dispatch(setBackground({modal: true}))
            }
        },
    ]
    return(
        <div className={styles.container}>
            <Items items={items} />
        </div>
    )
}