import React from 'react'
import { useSelector } from 'react-redux'
import AttributionModal from '../../../Modals/AttributionModal/AttributionModal'
import CreditsModal from '../../../Modals/CreditsModal/CreditsModal'
import BackgroundModal from './Background/Background'
import DimensionsModal from './Dimensions/Dimensions'

const Modals = () => {
    const originalTemplateId = useSelector(state => state.CanvasSlice.present.originalTemplateId)

    return(
        <div style={{
            position: 'absolute'
        }}>
            <DimensionsModal />
            <BackgroundModal />
            <AttributionModal id={originalTemplateId} />
            <CreditsModal message="You are out of downloads. Upgrade your plan to download and gain access to all Previewed features."/>
        </div>
    )
}

export default Modals;